/* Reset default margin and padding for all elements */
body, h1, h4, p, label, input, select {
    margin: 0;
    padding: 0;
}

/* Apply a background color and font family to the body */
body {
    background-color: #f0f0f0;
    font-family: Arial, sans-serif;
}

/* Center the h1 element and style it */
h2 {
    text-align: center;
    margin-top: 20px;
}

/* Style the h4 element */
h5 {
    text-align: center;
    color: #666;
    margin-top: -10px;
}

.center{
    text-align: center;
}

/* Style the error message for Safari */
#safariErr {
    display: none;
    text-align: center;
    color: red;
    margin-bottom: 20px;
}

/* Style the container for the main content */
.container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 25px;
    margin: 20px auto;
    max-width: 800px;
}

/* Style the connection section */
.connection {
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-bottom: 15px;
}

/* Style the label for connected device */
#lblConnTo {
    display: none;
    margin-right: 10px;
}

/* Style the select input for baud rates */
.baudrates {
    margin-right: 10px;
    height: 34;
    margin-top: 5px;
    width: 100%;
    max-width: 120px;
    border-radius: 5px;
}

/* Style the buttons */
.btn {
    border: solid gray 1px;
    margin-right: 10px;
    cursor: pointer;
}

#resetButton {
    border: solid gray 1px;
}

#consoleStartButton {
    background-color: rgb(161, 91, 252);
    color: #ffffff;
}

.scan-button-container {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
    justify-content: end;
}

input[type="button"] {
    width: 100%;
    max-width: 120px;
}

.wifi-logo {
    margin-right: 20px;
}


#uuid {
    height: 34px;
    margin-bottom: 15px;
}

.xterm-screen {
    width: 20px !important;
}

/* Style the scan button */
.scan-button {
    background-color: rgb(228, 228, 228);
    color: #1b1b1b;
    border: solid rgb(194, 194, 194) 1px;
    cursor: pointer;
    height: 100%;
    margin-top: 5px;
}

.scan-button:focus {
    background-color: rgb(228, 228, 228);
}


/* Style the UUID and WiFi credentials containers */
.uuid-container,
.wifi-credentials-container {
    box-shadow: 0 0 10px #0000001a;
    padding: 20px;
    padding-bottom: 25px;
    border-radius: 8px;
    margin-bottom: 10px;
    text-align: left;
}

/* Style the 'Uuid' and 'Wifi credentials' headings */
.uuid-container strong,
.wifi-credentials-container strong {
    color: #000000;
}

/* Style the flex container for input elements in wifi-credentials-container */
.flex {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.mr-sm {
    margin-right: 10px;
}

.ml-1{
    margin-left: 4px;
}

.ml-2{
    margin-left: 8px;
}

.ml-3{
    margin-left: 12px;
}

.ml-4{
    margin-left: 16px;
}

.ml-5{
    margin-left: 20px;
}

.mr-1{
    margin-right: 4px;
}

.mr-2{
    margin-right: 8px;
}

.mr-3{
    margin-right: 12px;
}

.mr-4{
    margin-right: 16px;
}

.mr-5{
    margin-right: 20px;
}

.mb-1{
    margin-bottom: 4px;
}

.mb-2{
    margin-bottom: 8px;
}

.mb-3{
    margin-bottom: 12px;
}

.mb-4{
    margin-bottom: 16px;
}

.mb-5{
    margin-bottom: 20px;
}

.mt-1{
    margin-top: 4px;
}

.mt-2{
    margin-top: 8px;
}

.mt-3{
    margin-top: 12px;
}

.mt-4{
    margin-top: 16px;
}

.mt-5{
    margin-top: 20px;
}

/* Style the input elements */
input[type="text"], input[type="password"] {
    width: 100%;
    padding-left: 10px;
    outline: none;
    margin-top: 0;
}

input[type=text]:focus, input[type=password]:focus {
    border: 2px solid rgb(161, 91, 252);
}

/* Style the '>' button in wifi-credentials-container */
.connect-button {
    background-color: rgb(161, 91, 252);
    color: #ffffff;
    border: none;
    cursor: pointer;
    width: 100%;
    max-width: 120px;
    margin: 0%;
    height: 34;
    margin-top: 5px;
}

.submit-button{
    background-color: rgb(161, 91, 252);
    color: #ffffff;
    cursor: pointer;
    margin: 0;
}


/* Hide the main content if using Safari */
#main {
    display: block;
}

input {
    border: solid #B0B3B9 1px;
    border-radius: 5px;
    margin-top: 5px;
}

.password-toggle{
    cursor: pointer;
    color: black;
}

.block{
    display:block;
}

.none{
    display:none;
}

ul {
    list-style-type: none; /* Removes the default bullet points */
    padding: 0;
}

.justify-center{
    justify-content: center;
}

.loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }